import React, { useEffect, useState } from 'react';
import { cookieStorage } from '../utils/CookieStore';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ACCEPT_COOKIES } from '../utils/constants';
import { getTitleByPartner } from '../utils/partnerTheme';
import { useIntercom } from 'react-use-intercom';

const ConsentScreen = () => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(true);
  const { boot } = useIntercom();

  useEffect(() => {
    setHasAcceptedCookies(() => !!cookieStorage.getItem(ACCEPT_COOKIES));
  }, [hasAcceptedCookies]);

  useEffect(() => {
    boot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptCookies = () => {
    cookieStorage.setItem(ACCEPT_COOKIES, JSON.stringify(true));
    setHasAcceptedCookies(true);
  };

  return (
    <>
      {!hasAcceptedCookies && (
        <ConsentScreenContent handleClick={acceptCookies} />
      )}
    </>
  );
};

export default ConsentScreen;

// Consent screen content
const ConsentScreenContent = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      height="0vh"
      w="100%"
      bg="transparent"
      zIndex={1000000}
    >
      <Flex
        w="100%"
        position="fixed"
        left="0"
        bottom="0"
        as="footer"
        bg="#fff"
        px={6}
        py={2}
        align="center"
        justify="center"
        fontSize="0.9rem"
      >
        <Flex
          maxW={'1200px'}
          display="flex"
          align="center"
          justify="center"
          gap={[5, 20, 20]}
          flexWrap={['wrap', 'nowrap', 'nowrap']}
        >
          <Box>
            <Text>Thank you for visiting {getTitleByPartner()}</Text>
            <Text>
              {getTitleByPartner()} uses cookies to provide necessary website
              functionality, improve your experience and analyze our traffic. By
              using the site, you agree to our privacy policy and our use of
              cookies. Thank you for visiting {getTitleByPartner()}
            </Text>
          </Box>

          <Button
            transition="all 1s"
            color="primary"
            bg="authBtn"
            border="1px solid black"
            py={6}
            width={{ base: '80%', md: 'initial' }}
            px={{ base: 5, md: 20 }}
            _hover={{
              bg: 'white',
              color: '#0F61D6',
              border: '1px solid #0F61D6',
            }}
            onClick={handleClick}
          >
            Got it
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
