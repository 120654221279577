import SimpleCrypto from 'simple-crypto-js';
import Cookie from 'js-cookie';

// const crypto = new SimpleCrypto(config.CRYPT_SECRET);
const crypto = new SimpleCrypto(process.env.NEXT_PUBLIC_CRYPT_SECRET);

const storage_key = 'PICKNFIX';

export const save_token = (token: string) => {
  try {
    Cookie.set(storage_key + '_TOKEN', crypto.encrypt(token));
  } catch (e) {
    throw new Error('Could not save token to cookie store');
  }
};

export const get_token = () => {
  try {
    const token = Cookie.get(storage_key + '_TOKEN');
    if (!token) return null;
    return crypto.decrypt(token);
  } catch (e) {
    throw new Error('Could not fetch user object from cookie store');
  }
};

export const remove_token = () => Cookie.remove(storage_key + '_TOKEN');

export const clear_store = () => {
  try {
    remove_token();
  } catch (e) {
    throw new Error('Could not delete user object from cookie store');
  }
};

export const cookieStorage = {
  getItem: (key: string) => {
    try {
      const value = Cookie.get(storage_key + '_' + key);
      if (!value) return null;
      return value;
    } catch (e) {
      throw new Error(`Could not fetch ${key} object from cookie store`);
    }
  },

  setItem: (key: string, value: string) => {
    try {
      Cookie.set(storage_key + '_' + key, value);
    } catch (error) {
      throw new Error(`Could not save ${key} to cookie store`);
    }
  },

  removeItem: (key: string) => {
    try {
      Cookie.remove(storage_key + '_' + key);
    } catch (error) {
      throw new Error(`Could not remove ${key} from cookie store`);
    }
  },

  setWithExpiry: function setWithExpiry(
    key: string,
    value: string,
    ttl: number
  ) {
    const item = {
      value: value,
      expiry: new Date().getTime() + ttl,
    };

    Cookie.set(storage_key + '_' + key, JSON.stringify(item));
  },

  getWithExpiry: function getWithExpiry(key: string) {
    const itemString = Cookie.get(storage_key + '_' + key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      this.removeItem(key);
      return null;
    }

    return item.value;
  },
};

export const is_logged_in = () => get_token() !== null;
