import React, { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from '@apollo/client';
import { get_token } from '../utils/CookieStore';

export const API_URL = `${process.env.NEXT_PUBLIC_PICKNFIX_URL}`;
export const GQLClient = () => {
  const token = get_token();

  const headers = {
    'content-type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const httpLink = new HttpLink({
    headers,
    uri: API_URL,
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    link: httpLink,
  });
};

interface Props {
  children: ReactNode;
}

const ApolloClientProvider: FC<Props> = ({ children }) => {
  const client = GQLClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
