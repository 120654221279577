import React, { MouseEventHandler } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import Link from 'next/link';
// import { useRouter } from 'next/router';

interface breadcrumbsProp {
  title: string;
  link: string;
  isFirst: boolean;
  isCurrent: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

interface ComponentProp {
  breadcrumbs: breadcrumbsProp[];
}

export const Breadcrumb = ({ breadcrumbs }: ComponentProp) => {
  // const router = useRouter();
  return (
    <Flex bg="#f6f9fc" py={1} px={1} mb={2} mr={2} flexWrap="wrap">
      {breadcrumbs?.map((breadcrumb: breadcrumbsProp) => (
        <React.Fragment key={breadcrumb?.title}>
          {breadcrumb?.isFirst && (
            <Box color="#5e72e4" fontSize="14px">
              {breadcrumb.onClick ? (
                <Box cursor="pointer" onClick={breadcrumb.onClick}>
                  {breadcrumb?.title}
                </Box>
              ) : (
                <Link href={breadcrumb?.link}>{breadcrumb?.title}</Link>
              )}
            </Box>
          )}

          {!breadcrumb?.isFirst && !breadcrumb?.isCurrent && (
            <>
              <Box px={1} color="#8898aa" fontSize="14px">
                {' '}
                /{' '}
              </Box>
              <Box cursor="pointer" color="#5e72e4" fontSize="14px">
                {breadcrumb.onClick ? (
                  <Box onClick={breadcrumb.onClick}>{breadcrumb?.title}</Box>
                ) : (
                  <Link href={breadcrumb?.link}>{breadcrumb?.title}</Link>
                )}
              </Box>
            </>
          )}

          {breadcrumb?.isCurrent && (
            <>
              <Box px={1} color="#8898aa" fontSize="14px">
                {' '}
                /{' '}
              </Box>
              <Box px={1}>
                <Text color="#8898aa" fontSize="14px">
                  {breadcrumb?.title}
                </Text>
              </Box>
            </>
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};
