import gql from 'graphql-tag';

export const FETCH_USER_QUERY = gql`
  query fetchUser($user_id: uuid!) {
    user(where: { id: { _eq: $user_id } }) {
      id
      email
      phone
      first_name
      last_name
    }
  }
`;